import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import { CardsThree } from "../homepage";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { RequestFormWithOnlyButton } from "../../components/form/form";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

import {
  useScrollPosition,
  useDisableBodyScroll,
  useUniqueId,
  useShareSessionStorge,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from "../../components/useDisableBodyScroll";
import { Features } from "../conversational-ai-platform";
import { right } from "fp-ts/lib/Either";

const TopImage = require("../../assets/img/feature/dialog/chatbot_builder_header.png");
const TopImage_mob = require("../../assets/img/feature/dialog/chatbot_builder_header.png");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../assets/images/homepage/group1.png");
export const cardTwo = require("../../assets/images/homepage/group4.png");
const section1 = require("../../assets/img/feature/dialog/chatbot_builder_sec_1.png");
const section2 = require("../../assets/img/feature/dialog/chatbot_builder_sec_2.png");
const section3 = require("../../assets/img/feature/dialog/chatbot_builder_sec_3.png");
const section4 = require("../../assets/img/feature/dialog/chatbot_builder_sec_4.png");
const section5 = require("../../assets/img/feature/dialog/chatbot_builder_sec_5.png");
const section6 = require("../../assets/img/feature/dialog/chatbot_builder_sec_6.png");
const section7 = require("../../assets/img/feature/dialog/chatbot_builder_sec_7.png");
const section8 = require("../../assets/img/feature/dialog/chatbot_builder_sec_8.png");
const section9 = require("../../assets/img/feature/dialog/chatbot_builder_sec_9.png");
const section10 = require("../../assets/img/feature/dialog/chatbot_builder_sec_10.png");
const section11 = require("../../assets/img/feature/dialog/chatbot_builder_sec_11.png");
const section12 = require("../../assets/img/feature/dialog/chatbot_builder_sec_12.png");
const section13 = require("../../assets/img/feature/dialog/chatbot_builder_sec_13.png");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const logs = require("../../assets/img/feature/updated/logs.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const conversationAI = require("../../assets/img/feature/updated/c_ai.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
export const c_ai = require("../../assets/img/feature/updated/c_ai.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const featureContent = [
  {
    icon: icon1,
    header: "No-code dialog canvas",
    content:
      "Our advanced chatbot builder gives you several powerful tools to create simple to complex multi-turn conversations without writing a single line of code. Whether you want the chatbot to execute tasks, provide personalized options to end users, validate user details, or simply do small talk - we got your covered.",
    image: section1,
    alt: "no code chatbot canvas",
    position: "right",
  },
  {
    icon: icon2,
    header: "Calling app workflows from chatbot",
    content:
      "Because we understand automating workplace support requires bots that are task oriented, we designed our platform to effortlessly unify chatbot and app workflow automation in a single platform so you can integrate chatbot with apps and call app workflows in a few clicks.",
    content2:
      "No more java scripting, custom development, backend integration etc. just click and go.",
    image: section2,
    link: "Learn More",
    url: "https://workativ.com/conversational-ai-platform/chatbot-automation",
    alt: "chatbot + workflow automation",
  },
  {
    icon: icon4,
    header: "Intuitive FAQ bot Builder",
    content:
      "Simple and easy to use UI to automate your FAQs in minutes. Key user queries, utterances and answers to automate all your manual FAQs and go-live instantly.",
    image: section3,
    position: "right",
    link: "Learn More",
    url: "https://workativ.com/conversational-ai-platform/faq-chatbot-builder",
    alt: "Intuitive FAQ in chatbot",
  },
  {
    icon: icon3,
    header: "Dynamic Notifications",
    content:
      "Proactively notify users on chat to take action at workplace. Be it updating their passwords, filling surveys, updating OKRs, personal info, or simply surprise them on their birthdays/anniversaries, etc. Never let your employees miss a beat.",
    content2:
      "Bot notifications can be setup using app triggers that can initiate the bot to take an action. e.g password expiry, employee onboarding etc. Endless possibilities.",
    image: section4,
    alt: "Dynamic Notifications",
  },
  {
    icon: icon3,
    header: "Role Based Access",
    content:
      "Give role-based access to users on chatbot. Let the chatbot smartly restrict user access for use-case that are sensitive. e.g salary details, adding users, etc and advanced access to managers to perform actions such as employee onboarding or off-boarding etc.",
    image: section5,
    position: "right",
    alt: "dialogs testing Role Based Access",
    additionalClassImageWidth: "width-90",
  },
  {
    icon: icon3,
    header: "Admin Portal Access",
    content:
      "Get full visibility and control on admin access based on their duties and roles. ",
    content2:
      "Track changes made by other admins and see who can publish the bot, make changes, and or invite other admins.",
    image: section6,
    alt: "Admin Portal Access",
  },
  {
    icon: icon3,
    header: "Go-live instantly with pre-built chatbot templates",
    content:
      "No time to build? No problem. Just download pre-built Bots in a single click and go live in minutes. No coding.",
    image: section7,
    link: "Learn More",
    position: "right",
    url:
      "https://workativ.com/conversational-ai-platform/chatbot-marketplace-templates",
    alt: "chatbot templates",
  },
  {
    icon: icon3,
    header: "Live chatbot testing",
    content:
      "Testing your chatbot is critical part of building great user experience. Using “Try Me” you can test your chatbot and conversations in real time. Testing chatbot helps you optimize conversations, add/edit intents or user utterance, and review end user experience on the go.",
    image: section8,
    alt: "live testing",
  },
  {
    icon: icon4,
    header: "Optimizing chatbot using training",
    content:
      "Great experience comes from small improvements over time. With workativ’s smart chatbot training feature, training your chatbot is never difficult. Using clicks, map irrelevant words, intents, and utterances to optimize and refine chatbot conversations in minutes.",
    image: section9,
    position: "right",
    alt: "optimizing dialogs using training",
  },
  {
    icon: icon4,
    header: "Chatbot analytics",
    content:
      "Get a deeper understanding of chatbot usage by employees. Track how employees use chatbots, drill down on most helpful conversations, peak durations, average time to resolution, most frequently used channels and more.",
    image: section10,
    link: "Learn More",
    url:
      "https://workativ.com/conversational-ai-platform/chatbot-analytics-performance",
    alt: "logs & analytics",
  },
  {
    icon: icon4,
    header: "Chat History",
    content:
      "Using logs, you can quickly comb through conversations to possibly spot unsuccessful conversation and improve chatbot effectiveness. Analyze chatbot and user conversations, user experience, and end-user feedback.",
    image: section11,
    position: "right",
    alt: "Conversation logs",
  },
  {
    icon: icon4,
    header: "Adaptive cards",
    content:
      "Workativ helps you format data you want to display in chat, using adaptive cards. Using adaptive cards, a chatbot can display data in a card view with images, links and buttons for better user experience.",
    image: section12,
    alt: "adaptive cards in chatbot",
  },
  {
    icon: icon4,
    header: "Personalize user experience",
    content:
      "Workativ helps you customize chatbot experience from an end user perspective. For example, chatbot onboarding and offboarding, session inactivity, collecting feedback, agent handover messages and much more that helps you personalize end-user experience.",
    image: section13,
    position: "right",
    alt: "Personalize user experience",
    additionalClass: "margin-bottom-0",
  },
];

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export const otherFeatures = [
  {
    image: c_ai,
    alt: "Conversational AI Platform​",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    new: true,
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
  },
  {
    image: marketplace,
    alt: "Chatbot Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approval",
    header: "Chatbot Approval​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot Builder | Workativ Assistant Chatbot"
        description="Build bot using ai chatbot builder without coding. Create dialogs and conversations for chatbot using advanced chatbot tools to bring your chatbot to life."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Free Chatbot Builder | Workativ Assistant Chatbot"
        ogDescription="Build bot using ai chatbot builder without coding. Create dialogs and conversations for chatbot using advanced chatbot tools to bring your chatbot to life."
      />
      <Container additionalClass={"feature-display-none"}>
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            image={TopImage}
            altImage={"chatbot builder and dialog management"}
            image_mob={TopImage_mob}
            additionalClassImage={"width-100"}
            additionalClassSection={"features_indvidual_dec"}
          >
            <TopFormWithImage.Header>Chatbot Builder</TopFormWithImage.Header>
            <TopFormWithImage.Content>
              Our easy-to-use no-code chatbot builder helps you build and manage
              simple to complex conversations, FAQs, integrate app workflows,
              and personalize your bot in minutes. Deliver autonomous workplace
              support with purpose built intelligent chatbots.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {/* {isSmall ? null : <OnScrollPopup />} */}
          {featureContent.map((feature, index) => {
            const isFeature = index === 0;
            if (feature.position === "right") {
              return (
                <RightImageWithContentFeature
                  isFeature={isFeature}
                  rightImage={feature.image}
                  image={feature.image}
                  altImage={feature.alt}
                  additionalClassImageWidth={feature.additionalClassImageWidth}
                  bgColor={
                    index + 1 === featureContent.length
                      ? "last-section-linear-background"
                      : false
                  }
                  additionalClass={
                    index + 1 === featureContent.length
                      ? "margin-bottom-0 feature-padding-change"
                      : "feature-padding-change"
                  }
                >
                  <RightImageWithContentFeature.Header>
                    <h3 className="font-section-header">
                      <span>{feature.header}</span>
                    </h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    {feature.link && feature.content2 && feature.content ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.link && feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                        {feature.content}
                      </p>
                    ) : feature.content && feature.content2 ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18">
                        {feature.content}
                      </p>
                    ) : null}
                  </RightImageWithContentFeature.SubHeader>
                  {feature.link && feature.url && (
                    <div className="card_link_landing">
                      <a
                        href={feature.url}
                        className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                      >
                        {feature.link}&nbsp; &#8594;
                      </a>
                    </div>
                  )}
                </RightImageWithContentFeature>
              );
            }
            return (
              <div className="features_container_left">
                <LeftImageWithContent
                  rightImage={feature.image}
                  image={feature.image}
                  altImage={feature.alt}
                  additionalClassImageWidth={feature.additionalClassImageWidth}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <h3 className="font-section-header">{feature.header}</h3>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.SubHeader>
                    {feature.link && feature.content2 && feature.content ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.link && feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                        {feature.content}
                      </p>
                    ) : feature.content && feature.content2 ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18">
                        {feature.content}
                      </p>
                    ) : null}
                  </LeftImageWithContent.SubHeader>
                  {feature.link && feature.url && (
                    <div className="card_link_landing">
                      <a
                        href={feature.url}
                        className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                      >
                        {feature.link}&nbsp; &#8594;
                      </a>
                    </div>
                  )}
                </LeftImageWithContent>
              </div>
            );
          })}
          <section className="cards_features ">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
